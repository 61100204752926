import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import AnatomySites from './routes/AnatomySites';
import Login from './routes/Login';
// import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <AnatomySites />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>

  // <BrowserRouter>
  //   <App />
  //   <Routes>
  //     <Route path="/" element={<Login />}>
  //       <Route path="dashboard" element={<AnatomySites />} />
  //       <Route
  //         path="*"
  //         element={
  //           <main style={{ padding: "1rem" }}>
  //             <p>There's nothing here!</p>
  //           </main>
  //         }
  //       />
  //     </Route>
  //   </Routes>
  // </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

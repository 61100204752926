import React, { useState, useEffect } from 'react';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import Cookies from 'js-cookie';
import { useNavigate, Outlet } from "react-router-dom"
// import AnatomySites from './AnatomySites';
import LoginCss from './Login.css';

const csrfURL = "https://user-dev.anatomymapper.com/sanctum/csrf-cookie";
const loginURL = "https://user-dev.anatomymapper.com/login2";
const googleSSOURL = "https://user-dev.anatomymapper.com/auth/redirect/google";

// const csrfURL = "http://amu.localhost.com/sanctum/csrf-cookie";
// const loginURL = "http://amu.localhost.com/login2";
// const googleSSOURL = "http://amu.localhost.com/auth/redirect/google";

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default function Login() {

    // React.useEffect(() => {
    //   axios.get(baseURL).then((response) => {
    //     alert(response.data);
    //   });

    // }, []);

    var token = '';
    const data = {
        email: '',
        password: ''
    };
    let navigate = useNavigate();

    const onHandleChange = (event) => {
        data[event.target.name] = event.target.value;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // STEP 1 get csrf cookies
        axios.get(csrfURL).then((response) => {
            // alert(response.data);
            // console.log(document.cookie.split('=')[1]);
            // token = document.cookie.split('=')[1];
            console.log(data);
            // const headers = {
            //   // accept: 'application/json',
            //   Authorization: 'Bearer ' + token
            // }
            axios
                .post(loginURL,
                    {
                        email: data['email'],
                        password: data['password'],

                    },
                    // {
                    //   crossDomain: true,
                    // }
                )
                .then((loginResponse) => {
                    console.log('reached success');
                    console.log(loginResponse);
                    if (loginResponse.data.status == 'success') {
                        console.log('reached here');
                        console.log(loginResponse.data);
                        navigate("./dashboard", { replace: true });
                    } else {
                        alert(loginResponse.data.error);
                    }

                }).catch((err) => {
                    console.log('reached error');
                    console.log(err.response.data.errors)
                })

        });

    }

    const handleGoogleLogin = (e) => {
        e.preventDefault();
        axios
            .get(csrfURL)
            .then((response) => {
                axios
                    .post(googleSSOURL,
                    )
                    .then((ssoResponse) => {
                        //set response in local storage
                        console.log(ssoResponse.data);
                        window.location.href = ssoResponse.data;
                    })
            });

    }


    return (
        <div className="Login">
            <Container fluid="sm" style={{ 'max-width': '400px' }}>
                <Row>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail">

                            <Form.Label
                                htmlFor="email"
                                className="form-label">
                                Email address
                            </Form.Label>

                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                className="form-control"
                                onChange={onHandleChange}
                                id="email" />

                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword">

                            <Form.Label
                                htmlFor="formGroupExampleInput"
                                className="form-label">
                                Password
                            </Form.Label>

                            <Form.Control
                                type="password"
                                name="password"
                                className="form-control"
                                onChange={onHandleChange}
                                id="password" />

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Remember me" />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        <br />
                        <br />
                        <Button variant="primary" onClick={handleGoogleLogin}>
                            Login with Google
                        </Button>

                    </Form>
                </Row>
            </Container>
            <Outlet />
        </div>

    );
}

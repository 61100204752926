import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom"

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

function AnatomySites() {

    const AMURL = 'https://user-dev.anatomymapper.com/api/anatomicsitesdata2?lang=en';
    const LogoutURL = 'https://user-dev.anatomymapper.com/logout2';

    // const AMURL = 'http://amu.localhost.com/api/anatomicsitesdata2?lang=en';
    // const LogoutURL = 'http://amu.localhost.com/logout2';


    let amArray = [];
    const [amRows, setAmRows] = useState([]);
    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();

    React.useEffect(() => {
        // let token = document.cookie.split('=')[1];
        const headers = {
            withCredentials: true,
            // xsrfCookieName: "XSRF-TOKEN",
            // xsrfHeaderName: "X-XSRF-TOKEN"
        }
        axios.get(
            AMURL,
            {},
            headers
        ).then((response) => {
            amArray = response.data.result;
            // console.log(amArray)
            // Object.entries(amArray).forEach(([key, value]) => (
            //     console.log(value['sort_index'])
            // ));
            const rowSet = Object.keys(amArray).map(key =>
                <tr>
                    <td>{amArray[key].sort_index}</td>
                    <td>{amArray[key].amid}</td>
                    <td>{amArray[key].flattened_term}</td>
                    <td>{amArray[key].synonym}</td>
                </tr>);

            // console.log(rowSet);
            setAmRows(rowSet);
            setLoading(false);
        });

    }, []);

    const Logout = () => {
        const headers = {
            withCredentials: true,
            // xsrfCookieName: "XSRF-TOKEN",
            // xsrfHeaderName: "X-XSRF-TOKEN"
        }
        axios.post(
            LogoutURL,
            {},
            headers
        ).then((response) => {
            console.log('logout successful')
            navigate("/", { replace: true });
        });
    }


    return (
        <div>
            <h1>Anatomy Site List - Guarded API URL</h1>
            <Button
                variant="primary"
                type="submit"
                onClick={Logout}
            >
                Logout
            </Button>
            {loading
                ? <p>Loading...</p>
                : <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>sort_index</th>
                            <th>amid</th>
                            <th>flattened_term</th>
                            <th>synonym</th>
                        </tr>
                    </thead>
                    <tbody>
                        {amRows}
                    </tbody>
                </Table>
            }
        </div>
    );
}

export default AnatomySites;
